<template>
  <div class="constrain">
    <div class="row-12">
      <div class="back-link md:col-12 flex items-center mt-4 md:mt-24 mb-12">
        <ArrowLeft />
        <a class="ml-4 back-btn" :href="backUrl">{{ parentPageName }}</a>
      </div>
    </div>
    <div class="row-12">
      <div class="md:col-8 md:offset-2">
        <p class="parent-page-title text-center">
          - {{ parentPageName }} -
        </p>
      </div>
      <div class="md:col-8 md:offset-2">
        <h1 class="mt-12 mb-12 md:mb-24 text-center" v-html="payload.seitentitel" />
      </div>
      <div class="md:col-8 md:offset-2">
        <div class="subtitle mb-24" v-html="payload.untertitel" />
      </div>
    </div>
  </div>
</template>

<script>

import ArrowLeft from '../../assets/images/arrow-left.svg';

export default {
  components: {
    ArrowLeft,
  },
  props: {
    payload: Object,
  },
  computed: {
    backUrl() {
      return `/${this.$store.state.router.locale}/${this.payload.parentSlug}`;
    },
    parentPageName() {
      return this.payload.parentTitle.toUpperCase();
    },
  },
  mounted() {
    // console.log(this.backUrl);
  },
};
</script>

<style lang="scss" scoped>
.parent-page-title {
  font-size: px(18);
  color: var(--color-blue-gray);
  font-weight: 100;
}

.subtitle {
  &:deep(p) {
    line-height: 1.75;
    color: var(--color-navy);
  }
}

</style>
